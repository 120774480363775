import { MarketId } from './markets'
import { CollateralId } from './tokens'

export enum StatsInstrumentType {
  OPTION = 'OPTION',
  PERP = 'PERP',
  SPOT = 'SPOT',
}

export type DailySnapshotTradingData = {
  volume: number
  premiums: number
  fees: number
  trades: number
}

export type DailySnapshotTvlData = { totalSupply: number; tvl: number; spotPrice: number }

export type DailySnapshot = {
  timestamp: number
  trading: Partial<Record<MarketId, Partial<Record<StatsInstrumentType, DailySnapshotTradingData>>>>
  tvl: Partial<Record<CollateralId, DailySnapshotTvlData>>
}

export enum StatsUserDuration {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

export type DailyUserSnapshot = {
  timestamp: number
  duration: StatsUserDuration
  traders: number
  activeTraders: number
  volume: number
  premiums: number
  fees: number
  trades: number
}

export enum StatsMarketFilter {
  ALL = 'ALL',
  ETH = 'ETH',
  BTC = 'BTC',
  SOL = 'SOL',
}

export enum StatsInstrumentFilter {
  ALL = 'ALL',
  OPTION = 'OPTION',
  PERP = 'PERP',
  SPOT = 'SPOT',
}

export enum StatsTimeFilter {
  ALL = 'ALL',
  THIRTY = 'THIRTY',
  SIXTY = 'SIXTY',
  ONE_EIGHTY = 'ONE_EIGHTY',
}

export enum StatsIntervalFilter {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export const EMPTY_DAILY_TRADING_SNAPSHOT = Object.values(MarketId).reduce(
  (dict, marketId) => ({
    ...dict,
    [marketId]: Object.values(StatsInstrumentType).reduce(
      (dict, instrumentType) => ({
        ...dict,
        [instrumentType]: { volume: 0, premiums: 0, fees: 0, trades: 0 },
      }),
      {} as Record<StatsInstrumentType, DailySnapshotTradingData>
    ),
  }),
  {} as Record<MarketId, Record<StatsInstrumentType, DailySnapshotTradingData>>
)

export const EMPTY_DAILY_TVL_SNAPSHOT = Object.values(CollateralId).reduce(
  (dict, collateralId) => ({
    ...dict,
    [collateralId]: {
      totalSupply: 0,
      tvl: 0,
      spotPrice: 0,
    },
  }),
  {} as Record<CollateralId, DailySnapshotTvlData>
)
