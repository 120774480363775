import React from 'react'
import { useTheme, XStack } from 'tamagui'

import { TextColor } from '../utils/text'
import BodyText from './BodyText'
import Card from './Card'

type Props = {
  xLabel: string
  totalLabel?: string

  legend: { dataKey: string; color: string; value: string; valueColor?: TextColor }[]
}

export default function ChartTooltip({ xLabel, totalLabel, legend }: Props) {
  const theme = useTheme()

  return (
    <Card backgroundColor="$appBg90">
      <XStack gap="$2">
        <BodyText>{xLabel}</BodyText>
        {totalLabel ? <BodyText marginLeft="auto">{totalLabel}</BodyText> : null}
      </XStack>
      <XStack height={1} backgroundColor="$hairline" width="100%" marginVertical="$0.5" />
      {legend.length ? (
        legend.map(({ dataKey, value, valueColor, color }) => {
          return (
            <XStack gap="$2" alignItems="center" key={dataKey}>
              <XStack alignItems="center" gap="$1">
                <XStack
                  backgroundColor={color in theme ? theme[color]?.get() : color}
                  width={8}
                  height={8}
                  borderRadius="$1"
                />
                <BodyText color="secondary">{dataKey}</BodyText>
              </XStack>
              <BodyText marginLeft="auto" color={valueColor}>
                {value}
              </BodyText>
            </XStack>
          )
        })
      ) : (
        <BodyText color="secondary">No data</BodyText>
      )}
    </Card>
  )
}
